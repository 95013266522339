body {
  overflow: overlay;
}

li {
  font-family: 'Readex Pro', sans-serif;
}

body::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  width: 0.5em;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(169, 169, 169);
  border-radius: 1em;
}

body::-webkit-scrollbar-corner {
  background-color: rgba(169, 169, 169, 0);
}

.modal::-webkit-scrollbar {
  /* width: 0.5em; */
  display: none;
  background-color: rgba(255, 255, 255, 0);
}

.modal::-webkit-scrollbar-thumb {
  display: none;
  background-color: rgb(169, 169, 169);
  /* border-radius: 1em; */
  /* border-top-right-radius: 1em; */
  /* border-bottom-right-radius: 1em; */
}

.wrapper:hover .slide {
  transition: 1s;
  left: 0;
}

.slide {
  position: absolute;
  left: -100px;
  width: 100px;
  height: 100px;
  background: blue;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 2s;
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}